import request from '@/utils/request'

export const getAllActivity = (params) => {
  return request({
    method: 'GET',
    url: '/v2/api/activity/getAllActivity',
    params
  })
}

export const activityDetail = ({ id, activityCode }) => {
  return request({
    method: 'GET',
    url: `/v2/api/activity/activity_detail/${id}/${activityCode}`
  })
}

/**
 * 微信支付
 * @param {支付请求参数} data
 * @returns
 */
export const pay = data => {
  return request({
    method: 'POST',
    url: '/v2/activityWxPay/create_order',
    data
  })
}

/**
 * 微信支付回调API
 * @param {微信支付回调} data
 * @returns
 */
export const payResult = data => {
  return request({
    method: 'POST',
    url: '/v2/activityWxPay/payState',
    data
  })
}
